<template>
    <div class="form__group"
        :class="{'form__group--error': errorMessage}"
    >
        <slot name="label">
            <label class="form__label">
                {{label}}
            </label>
        </slot>

        <slot></slot>

        <transition name="slide-down">
            <div
                v-if="errorMessage"
                class="form__error"
            >
                {{errorMessage}}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
    props: {
        label: {
            type: String,
        },
        errorMessage: {
            type: String,
        }
    }
}
</script>
