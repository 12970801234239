const StepMixin =  {
    data() {
        return {
            activeErrors: {},
        }
    },
    props: {
        stepSettings: {
            type: Object,
        },
    },
    methods: {
        /**
         * Notify the parent component that we are ready to go to the previous step
         */
        previousStep() {
            this.$emit('previousStep');
        },
        /**
         * Notify the parent component that we are ready to go to the next step
         */
        nextStep() {
            this.$emit('nextStep');
        },
        /**
         * Set all error messages in step data as active errors
         */
        setAllValidationMessages() {
            this.stepData.form.validationErrors.forEach(error => {
                this.activeErrors[error.id] = error.errorMessage;
            })
        },
    }
}

export default StepMixin;