import { handleError } from './error-handler';

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
}

const generateUrl = (path, params) => {
    let url;

    url = new URL(path);

    if(params) {
        // add the params if there are any
        Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
                params[key].forEach(element => url.searchParams.append(`${key}\[\]`, element));
            } else url.searchParams.append(key, params[key]);
        })
    }

    return url.toString();
}

// process a fetch call with the try catch logic & optional inspector
const doFetch = async (url, params) => {
    try {
        const request = await fetch(url, params);
        const response = await request.json();

        // if something when't wrong
        if (!request.ok)  {
            // we throw the error of the request
            throw request.status;
        }

        return response;
    } catch (error) {
        handleError(error);
    }
};

export const get = async (path, params, signal, inspector) => {
    const url = generateUrl(path, params);

    return doFetch(url, {
        method: 'GET',
        headers,
        params,
        signal
    }, inspector);
}

export const post = async (path, data, params, signal, inspector) => {
    const url = generateUrl(path, params);
    const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'POST',
        body,
        headers,
        signal
    }, inspector);
}

export const put = async (path, data, params, signal, inspector) => {
    const url = generateUrl(path, params);
    const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'PUT',
        body,
        headers,
        signal
    }, inspector);
}

const dataManager = {
    install: (app) => {
        app.config.globalProperties.$get = (url, params, inspector) => get(url, params, inspector);
        app.config.globalProperties.$post = (url, data, params, inspector) => post(url, data, params, inspector);
        app.config.globalProperties.$put = (url, data, params, inspector) => put(url, data, params, inspector);
    }
}

export default dataManager;